body {
    margin: 0;
    font-family: Open Sans;
    font-size: 14px;
    color: #555555;
}

a {
    text-decoration: none;
    color: #3366d1;
}

h1 {
    margin: 0;
    font-size: 25px;
    font-weight: normal;
}
