#sidebar {
    background-color: #03293e;
    overflow: hidden;
    padding: 0;
    position: fixed;
    left: 0;
    color: white;
    box-sizing: border-box;
    white-space: nowrap;
    user-select: none;
    width: 200px;
    transition: all .5s;
    height: 100%;
    margin-top: -6px;
    top: 74px;
    z-index: 101;
}

#sidebar.collapsed {
    width: 40px;
}

#sidebar-expander {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    width: 20px;
    background-color: #07c6c1;
    background-image: url(../img/chevron_left.svg);
    cursor: pointer;
}

#sidebar.collapsed #sidebar-expander {
    background-image: url(../img/chevron_right.svg);
}

#sidebar ul {
    list-style-type: none;
    margin: 20px 0 0;
    padding: 0;
}

#sidebar li {
    height: 40px;
    border-bottom: 1px solid #03293e;
    font-size: 17px;
    font-family: Open Sans;
}

#sidebar li a {
    display: block;
    background-color: #053a59;
    height: 25px;
    white-space: nowrap;
    color: white;
    padding: 7.5px;
}

#sidebar li img {
    height: 25px;
    float: left;
    vertical-align: middle;
    padding-right: 10px;
}

#sidebar li a.active {
    background-color: #031521;
    border-right: 4px solid #07c6c1;
}

#sidebar li a:hover {
    background-color: #031521;
}
